<!--
 * @Author: wjc
 * @Date: 2023-11-14 17:43:09
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-10-12 16:10:03
 * @Description: 
-->
<template>
  <div class="company-container">
    <MiniTitle title="企业展示" icon="qyzs"></MiniTitle>
    <el-carousel
      :autoplay="true"
      height="328px"
      indicator-position="none"
      class="rounded-8px mb-32px"
    >
      <el-carousel-item
        v-for="item in list"
        :key="item.id"
        :indicator-position="isXs ? 'none' : ''"
        :arrow="isXs ? 'always' : 'hover'"
      >
        <div class="banner-list">
          <div v-for="c in item.children" :key="c.name" class="banner wh-full bg-white">
            <MLink v-if="c.jumpLink" :to="c.jumpLink" target="_blank">
              <img :src="`${downloadUrl}/${c.image}`" class="object-contain" />
            </MLink>
            <img v-else :src="`${downloadUrl}/${c.image}`" :alt="c.name" class="object-contain" />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script setup lang="ts">
  import config from '~/constants'
  import type { EnterpriseCase, ChunkList } from '~/models/enterprise'
  import { apiBase } from '~/constants'

  defineOptions({
    name: 'Company',
  })

  const props = defineProps<{
    list: ChunkList<EnterpriseCase>[]
  }>()
  const downloadUrl = computed(() => `${apiBase}/file/download`)
  const $screen = useScreen()
  const isXs = computed(() => $screen.higherThan('xs'))
</script>

<style scoped lang="scss">
  .company-container {
    @apply bg-#F7F8F9 my-48px;
    .banner-list {
      @apply py-24px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 130px 130px;
      gap: 24px;
      @screen lt-xs {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 130px 130px;
      }
      .banner {
        @apply overflow-hidden;
        img {
          @apply wh-full;
        }
      }
    }
  }
</style>
